import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Live at The Mussel Inn—Feb 19, 2010" />

		<h2>Live at The Mussel Inn—Feb 19, 2010</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST02 live at the mussel inn cover.jpg"
			alt="Jesse Rivest - Live at The Mussel Inn—Feb 19, 2010 - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Words from Jesse</h3>
		<p>
			<span className="italic">Live at The Mussel Inn—Feb 19 2010</span> started as a gift because Andrew (owner/manager) recorded my two sets without me asking or knowing.
			I just love this venue—the atmosphere, the people, the food, the homemade beer, the amazing accommodation,
			the stunning starlit sky, the warm fires in or out, the monument of dead mobile phones,
			the glowworms across the road, oh yeah and the stage sound—I could go on.
	        It has been and is my favourite place to play in New Zealand.
			I think you can really feel this vibe from me in these two sets,
			and I felt a lot of warmth from the folks inside as well as the ones listening from the patio and the outdoor fire.
			Please send this web page to your friends—share!
		</p>

		<p className="card">
			<span className="card">
				Quite some time ago, I decided to remove the downloads for this album from my site.
				I had never cleared the licenses to have my own recordings of the other artists' songs I covered;
				even though this album has never been for sale,
				I technically still would need to clear licenses.
				Some day in the future, I'll do a new live album. Properly 😎.
			</span>
		</p>

		<h3>Track listing</h3>
		<ol>
			<h4>Set 1</h4>
			<li>Intro (0:57) <br /> CA-6P8-10-00001</li>
			<li>Do It Now (4:41) <br /> CA-6P8-10-00002 - © Jesse Rivest 2006 *</li>
			<li>Mesmerize Me (4:53) <br /> CA-6P8-10-00003 - © Jesse Rivest 2006 *</li>
			<li>Thought He'd Ask For A Dollar (6:22) <br /> CA-6P8-10-00004 - © Jesse Rivest 2010</li>
			<li>Same As You (6:19) <br /> CA-6P8-10-00005 - © Jesse Rivest 2010</li>
			<li>My Ticket Home (5:19) <br /> CA-6P8-10-00006 - © Jesse Rivest 2010</li>
			<li>I Want To Sing That Rock And Roll (Gillian Welch) (5:41) <br /> CA-6P8-10-00007 - © Gillian Howard Welch and David Todd Rawlings (BMI)</li>
			<li>Down Again (8:15) <br /> CA-6P8-10-00008 - © Jesse Rivest 2008 +</li>
			<li>Worth the Wait (6:51) <br /> CA-6P8-10-00009 - © Jesse Rivest 2006 *</li>
			<li>Dragon &amp; Kitty (3:53) <br /> CA-6P8-10-00010 - © Jesse Rivest 2006 *</li>

			<h4>Set 2</h4>
			<li>Welly Wonky (9:29) <br /> CA-6P8-10-00011 - © Jesse Rivest 2008 +</li>
			<li>The Romance of Kaslo (5:36) <br /> CA-6P8-10-00012 - © Jesse Rivest 2008 +</li>
			<li>Indie Grocery Store / Let Me Be (Xavier Rudd) (8:02) <br /> CA-6P8-10-00013 - © Jesse Rivest 2006 / © Xavier Peter I Rudd (APRA) +</li>
			<li>A Traveller's Song (8:30) <br /> CA-6P8-10-00014 - © Jesse Rivest 2006 *</li>
			<li>Spring Wind (Greg Brown) (5:38) <br /> CA-6P8-10-00015 - © Gregory Dane Brown (ASCAP)</li>
			<li>Summer Grace (7:06) <br /> CA-6P8-10-00016 - © Jesse Rivest 2008 +</li>
			<li>New Trail Old Times (4:36) <br /> CA-6P8-10-00017 - © Jesse Rivest 2010</li>
			<li>Greyhound Through the Rockies (6:02) <br /> CA-6P8-10-00018 - © Jesse Rivest 2008 +</li>
			<li>Fans (Kings of Leon) (3:37) <br /> CA-6P8-10-00019 - © Anthony Caleb Followill, Cameron Matthew Followill, Ivan Nathaniel Followill, and Michael Jared Followill (SESAC)</li>
		</ol>
		<p>
			* originally released on <span className="italic">Seventeen Oh-Two Oh-Six</span> in 2006<br />
			+ originally released on <span className="italic">Jesse Rivest Podcast</span> in the year indicated
		</p>
		<p>
			This recording: catalog number JRIVEST02, © Jesse Rivest 2010, All Rights Reserved<br />
			UPC/EAN: n/a<br />
			This recording is currently not available through any digital distribution platforms (downloads, streaming, etc.).<br />
			Released: March 16, 2010
		</p>

		<h3>Credits</h3>
		<p>
			Recorded &quot;off the desk&quot; by Andrew at The Mussel Inn.<br />
			EQ'd &amp; mastered by Jesse Rivest.<br />
			Photographs (cover and back) &#169; Kyle Mulinder, 2010: used with permission.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Zhenya Topov (a.k.a. Gene Alexander) for eq and mastering help, time, and knowledge.<br />
			Thanks to Kyle 'Te Kiwi' Mulinder and Hayley Dawn Williamson for pre-release testing.
		</p>

		<h3>Extras</h3>
		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST02 live at the mussel inn back.jpg"
			alt="Jesse Rivest - Live at The Mussel Inn—Feb 19, 2010 - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
